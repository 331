<template>
  <el-form :rules="rules" v-loading="loading" ref="form" :model="userModel" label-width="15rem">
    <el-link type="primary" :underline="false" class="mt-1 mb-2"
             @click="$router.push('/administration/user-registrations')"><i class="el-icon-back" /> Назад</el-link>
    <el-container style="padding: 20px">
      <el-header height="2rem !important">Регистрация пользователя</el-header>
      <el-row :gutter="25">
        <el-col :span="10">
          <el-form-item prop="email" label="E-mail:">
            <el-input disabled v-model="userModel.email"></el-input>
          </el-form-item>
          <el-form-item class="mb-0" prop="organizationName" label="Наименование организации:">
            <el-input disabled v-model="userModel.organizationName"></el-input>
          </el-form-item>
          <el-form-item class="mt-4" prop="roleGroupIds" label="Роль:">
<!--            <el-select multiple v-model="userModel.roleGroupIds" style="width: 100%; min-height: 23px;" id="reg_role&#45;&#45;select">
              <el-option v-for="item in roleGroups"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>-->
            <el-input disabled :value="'Внешний пользователь'"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item prop="organizationId" label="Организация:">
            <remote-search-input
              style="width: 100%"
              v-model="userModel.organizationId"
              search-url="/api/organizations/SearchByNameForUser"
              size="large"
              return-prop="id"
              :start-length="3"
              :allow-create="false"
              id="reg_org--input"
            />
          </el-form-item>

          <el-form-item prop="userName" label="Логин:">
            <el-input v-model="userModel.userName" id="reg_name--input"></el-input>
          </el-form-item>
          <el-form-item class="mb-0" prop="password" label="Пароль:">
            <el-input v-model="userModel.password" id="reg_pass--input"></el-input>
          </el-form-item>
          <el-form-item class="mt-0 mb-0" label="">
            <el-link type="primary" :underline="false" class="mt-1 reg_pass_generation--link"
                    @click="generateRandomPassword" >Сгенерировать пароль</el-link>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="mt-0" v-if="!registrationComplete && !registrationReject">
        <el-button
          :disabled="loading"
          class="login-button mt-4"
          @click="handleRejectRegistration"
        >Отклонить</el-button>
        <el-button
          :disabled="loading"
          class="login-button mt-4"
          id="reg-button--submit"
          type="primary"
          @click="handleCreateUser"
        >Зарегистрировать</el-button>
      </div>
      <div v-else-if="registrationComplete">
        Пользователь успешно зарегистрирован в Системе.
        Логин и пароль необходимо отправить на e-mail, указанный в заявке.
      </div>
      <!-- <div v-else-if="registrationReject">
        Заявка отклонена.
        При необходимости отправьте уведомление на e-mail, указанный в заявке.
      </div> -->
    </el-container>
  </el-form>
</template>

<script>
import regApi from '@/api/administration/userRegistration';
import identityApi from '@/api/identity';
import remoteSearchInput from '@/components/Ui/RemoteSearchInput';
import validationRules from '@/mixins/validationRules';

export default {
  name: 'userRegistration',
  mixins: [validationRules],
  components: { remoteSearchInput },
  data() {
    return {
      rules: {
        organizationId: [
          {
            required: true,
            message: 'Необходимо выбрать организацию',
            trigger: 'blur',
          },
        ],
        /* roleGroupIds: [
          {
            required: true,
            message: 'Необходимо выбрать как минимум одну роль',
            trigger: 'blur',
          },
        ], */
        userName: [
          {
            required: true,
            message: 'Необходимо указать логин',
            trigger: 'blur',
          },
          {
           min: 5, max: 30, message: 'От 5 до 30 символов', trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: 'Необходимо указать пароль',
            trigger: 'blur',
          },
          { validator: this.validatePassword, trigger: 'blur' }
        ],
      },
      loading: false,
      userModel: {},
      // roleGroups: [],
      registrationComplete: false,
      registrationReject: false
    };
  },
  async mounted() {
    if (this.$route.query.id) {
      this.loading = true;
      await this.getUserModel(this.$route.query.id);
      // await this.getRoleGroups();
      this.loading = false;
    } else {
      // error
    }
  },
  methods: {
    async getUserModel(id) {
      const res = await regApi.getUserRegistration(id);
      this.userModel = res.data;
      this.userModel.organizationId = '';
    },
    /* async getRoleGroups() {
      const res = await identityApi.getRoleGroups();
      this.roleGroups = res.data.items;
    }, */
    async generateRandomPassword() {
      const res = await identityApi.generateRandomPassword();
      this.userModel.password = res.data;
    },
    async handleCreateUser() {
      const valid = await this.$refs.form.validate();
      if (!valid) {
        return;
      }

      this.$confirm('Вы действительно хотите зарегистрировать пользователя с указанными параметрами?', 'Подтверждение', {
        confirmButtonText: 'ОК',
        cancelButtonText: 'Отмена',
        type: 'warning',
      }).then(async () => {
        await this.сreateUser();
      });
    },
    async сreateUser() {
      this.loading = true;
      const response = await regApi.createUser(this.userModel);

      if (response && response.data) {
        if (!response.data.succeeded) {
          this.$alert(response.data.resultMessage, 'Регистрация', {
            confirmButtonText: 'OK',
            type: 'warning',
            customClass: 'alert-30',
          });
        } else {
          this.$alert(response.data.resultMessage, 'Регистрация', {
            confirmButtonText: 'OK',
            type: 'success',
            customClass: 'alert-30',
            showClose: false,
            callback: this.registrationCallback
          });
        }
      }

      this.loading = false;
    },
    async handleRejectRegistration() {
      this.$confirm('Вы действительно хотите отклонить заявку?', 'Подтверждение', {
        confirmButtonText: 'ОК',
        cancelButtonText: 'Отмена',
        type: 'warning',
      }).then(async () => {
        await this.rejectRegistration();
      });
    },
    async rejectRegistration() {
      this.loading = true;
      const response = await regApi.rejectRegistration(this.userModel.id);

      if (response && response.data) {
        this.$alert('Заявка отклонена. При необходимости отправьте уведомление на e-mail, указанный в заявке.', 'Регистрация', {
          confirmButtonText: 'OK',
          type: 'warning',
          customClass: 'alert-30',
        });
      }

      this.registrationReject = true;
      this.loading = false;
    },
    registrationCallback() {
      this.registrationComplete = true;
    }
  }
};
</script>

<style scoped>

</style>
